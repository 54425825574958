/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/widgets";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/accordions";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/tooltips";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/popovers";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/bootstrap-alerts";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/portfolio";
@import "../assets/styles/components/pricing-table";
@import "../assets/styles/components/email/mail-list-container";
@import "../assets/styles/components/email/mail-sidebar";
@import "../assets/styles/components/email/message-content";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/tickets";
@import "../assets/styles/components/project-list";
@import "../assets/styles/components/product-tile";
@import "../assets/styles/components/user-listing";
@import "../assets/styles/components/landing";
@import "../assets/styles/components/chats";
@import "../assets/styles/components/kanban";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/contex-menu";
@import "../assets/styles/components/plugin-overrides/no-ui-slider";
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/rating";
@import "../assets/styles/components/plugin-overrides/date-picker";
@import "../assets/styles/components/plugin-overrides/wizard";
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/sweet-alert";
@import "../assets/styles/components/plugin-overrides/full-calendar";
@import "../assets/styles/components/plugin-overrides/react-photo-gallery";
@import "../assets/styles/components/plugin-overrides/react-tag-autocomplete";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

.product-img-upload {
  padding: 4px;
  border: 2px dashed #ddd;
  border-radius: 12px;
  position: relative;
  width: 100%;
  & > div {
    position: relative;
    min-height: 200px;
    height: 100%;
    img {
      inset: 0;
      object-fit: scale-down;
    }
  }
}

.upload-new-product-img {
  position: absolute;
  opacity: 0;
  height: 100%;
  z-index: 2;
  inset: 0;
  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.accordion-header {
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: start;
    font-size: 16px;
    background: #f6f6f6;
    border: 1px solid $border-color;
    padding: 0.9375rem;
    transition: transform 0.3s all ease;
    &.collapsed {
      transition: transform 0.3s all ease;
      i {
        transform: rotate(180deg);
        color: #9e9da0;
      }
    }
  }
}

.pagination-btns {
  border-radius: 50%;
  font-weight: 500;
  color: #4e4e4e;
  background: none;
  border: none;
  * {
    padding: 8px;
    margin: 0 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &:first-child,
  &:last-child {
    font-size: 18px;
  }
}
.pagination-btns .active {
  color: #df196d;
}

.dashboard-card {
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
}
.dashboard-card.policies {
  background-color: #eaf1fe;
}
.dashboard-card .dashboard-card-name .dash-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.dashboard-card .dashboard-card-name .dash-number {
  font-size: 24px;
  font-weight: 500;
}
.dashboard-card.total-renewal {
  background-color: #fef8ed;
}
.dashboard-card.premium {
  background-color: #edf6ed;
}
.dashboard-card.payout {
  background-color: #ce232710;
}

.radial-chart-non-certify-info *,
.radial-chart-certify-info * {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
}
.radial-chart-non-certify-info h6,
.radial-chart-certify-info h6 {
  font-size: 14px;
}
.radial-chart-non-certify-info h4 {
  font-size: 24px;
  color: #f5b544;
}
.radial-chart-certify-info h4 {
  font-size: 24px;
  color: #2772f0;
}
.radial-chart-non-certify-info p,
.radial-chart-certify-info p {
  font-size: 14px;
  color: #091b3d50;
}
.radial-chart-non-certify-info .profit-signal,
.radial-chart-certify-info .profit-signal {
  font-size: 14px;
  color: #4b9f47;
}
.radial-chart-non-certify-info .loss-signal,
.radial-chart-certify-info .loss-signal {
  font-size: 14px;
  color: #e6521f;
}
.radial_chart svg {
  width: unset !important;
  height: unset !important;
}
.total-premium-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}
.total-premium-amount {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  color: #091b3d;
}
.total-premium-amount span {
  font-size: 24px;
}
.total-premium-amount p {
  font-size: 14px;
  margin-top: 10px;
}
.total-premium-percent span {
  font-weight: 700;
  font-size: 14px;
  color: #4b9f47;
}
.CircularProgressbar {
  width: 100%;
}
.average-product-sale-progess {
  font-family: "Cera Pro", sans-serif;
}
.average-product-sale-progess > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-sale-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // max-width: calc(125px - 24px);
}
.product-sale-progress h5 {
  font-weight: 700;
  font-size: 24px;
  color: #091b3d;
  text-align: center;
  margin: 24px 0 10px;
}
.product-sale-progress p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #091b3d50;
  margin-bottom: 1rem;
}
.average-product-sale-progess .sale-desc {
  padding: 24px;
  border-radius: 24px;
  background: #f5b54410;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
}
.sidebar .nav .nav-item + .nav .nav-item .nav-link i {
  font-size: 1.125rem;
  line-height: 1;
  margin-left: auto;
  color: rgba(153, 174, 161, 0.9607843137);
}
.setting_checkbox {
  opacity: 100% !important;
  position: unset !important;
  margin: 2px 8px 0 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa !important;
}
label.form-label {
  font-size: 14px;
  color: #808c99;
  font-weight: 500;
}
.form-control,
.swal2-modal .swal2-content .swal2-input {
  border: 1px solid #e0e0e0 !important;
}
select.form-control,
.swal2-modal .swal2-content select.swal2-input {
  outline: 0 !important;
  color: #495057 !important;
}
select.form-control::placeholder,
.swal2-modal .swal2-content select.swal2-input::placeholder {
  outline: 0 !important;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url(../assets/images/btn_close.svg);
  background-position: center;
  background-size: 1em;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.pastel_red {
  background-color: #d64545;
}
.pastel_violet {
  background-color: #9065e5;
}
.pastel_blue {
  background-color: #5485e5;
}
.pastel_yellow {
  background-color: #e5b045;
}
.piechart_list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.piechart_list li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 4px;
}
.piechart_list li > div {
  width: 18px;
  height: 10px;
}
.piechart_list li > p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.latest_user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.latest_user img {
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  padding: 8px;
}
.no-padding {
  padding-left: 5px;
  padding-right: 7px;
}
.media_grid_card {
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 189px;
  overflow: hidden;
  position: relative;
  border: 2px solid #e43c52;
  border-radius: 10px;
  min-width: 125px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.ocassion_theme_card {
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  border: 2px solid #e43c52;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px;
  aspect-ratio: 16/11;
  position: relative;
  >button {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    width: 25px;
    height: 25px;
    display: flex;
    border-radius: 50%;
    background-color: #ddd;
    border: none;
  }
  .remember_wrapper{
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-bottom: 0;
    label{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin: 0;
        span{
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.10000000149011612px;
            color: #696974;
            margin-left: 0;
            user-select: none;
        }
        input{
            opacity: 0;
            position: absolute;
            inset: 0;
            margin-bottom: 0;
            z-index: 3;
            cursor: pointer;
            & + span{
                height: 25px;
                width: 25px;
                border-radius: 50px;
                border:1px solid #000;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -2px;
                img{
                    opacity: 0;
                    transition: 0.3s;
                    width: 100%;
                }
            }
            &:checked + span{
                img{
                    opacity: 1;
                }
            }
        }
    }
    a{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.10000000149011612px;
        color: #24242D;
        text-decoration: none;
    }
}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}