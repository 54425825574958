/* Dashboard */

.card-statistics {
  .highlight-icon {
    height: 53px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    i {
      font-size: 27px;
    }
  }

  .card-col {
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: none;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      border: none;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {

        &:after,
        &:before {
          content: "";
          position: absolute;
          background: $border-color;
        }
      }

      &:first-child {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          right: 0;
        }

        &:after {
          bottom: 0;
          width: 1px;
          height: 100%;
          right: 0;
        }
      }

      &:nth-child(2) {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          left: 0;
        }
      }

      &:nth-child(3) {
        &:before {
          width: 1px;
          height: 100%;
          right: 0;
        }
      }
    }
  }
}

.card-revenue-table {
  .revenue-item {
    border-bottom: 1px solid $border-color;
    @extend .py-3;

    &:last-child {
      border-bottom: 0;
      @extend .pb-0;
    }

    &:first-child {
      @extend .pt-0;
    }

    .revenue-desc {
      margin-right: auto;
      width: 80%;

      p {
        margin-bottom: 0;
      }
    }

    .revenue-amount {
      margin-left: auto;
      width: 40%;

      p {
        font-size: 1.25rem;
        font-weight: $font-weight-medium;
        text-align: right;

        .rtl & {
          text-align: left;
        }
      }
    }
  }
}

.card-revenue {
  background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
  background-size: cover;
  color: color(white);

  .highlight-text {
    font-size: 1.875rem;
    font-weight: $font-weight-medium;
  }

  .badge {
    background-color: rgba(color(white), .2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem;
  }
}

.card-weather {
  background: $card-bg;

  .card-body {
    background: $white;

    &:first-child {
      //background: url("../../images/samples/weather.svg") no-repeat center;
      background-size: cover;
    }
  }

  .weather-date-location {
    padding: 0 0 38px;
  }

  .weather-data {
    padding: 0 0 4.75rem;

    i {
      font-size: 5.313rem;
      line-height: 1;
    }
  }

  .weakly-weather {
    background: $card-bg;
    overflow-x: auto;

    .weakly-weather-item {
      flex: 0 0 14.28%;
      border-right: 1px solid $border-color;
      padding: 1rem;
      text-align: center;

      i {
        font-size: 1.2rem;
      }

      &:last-child {
        border-right: 0;
      }

      .symbol {
        color: $text-muted;
        font-size: 1.875rem;
        font-weight: 300;
      }
    }
  }
}

.product-chart-wrapper {
  height: 92%;
}

#dashboardTrendingProgress {
  width: 60px;
}

.dashboard-bar-chart-legend {
  .col {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .bg {
      margin-left: auto;
      margin-right: auto;
      height: 5px;
      width: 30px;
      display: block;
      margin-top: 5px;
    }

    &:nth-child(1) {
      .bg {
        background: theme-color(info);
      }
    }

    &:nth-child(2) {
      .bg {
        background: theme-color(primary);
      }
    }

    &:nth-child(3) {
      .bg {
        background: theme-color(danger);
      }
    }
  }
}

.intro-banner {
  background: #dbe4ec;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 2px;

  .banner-image {
    width: 24%;
    max-width: 100%;

    img {
      display: block;
      margin: auto;
    }
  }

  .content-area {
    width: 58%;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.sales-mini-tabs {
  border-bottom: none;

  .nav-item {
    .nav-link {
      border: none;
      font-weight: 500;
      padding: 5px 15px;
      border-radius: 2px;
      transition-duration: 0.7s;
      transition-property: "color";

      &.active {
        font-weight: 600;
        background: lighten($card-bg-varient, 4%);
        color: theme-color(primary);
      }
    }
  }
}
.dashboard-custom-date-picker {
  width: 100%;
  height: 100%;
  >div {
    width: 100%;
    height: 100%;
  }
  .react-datepicker {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    .react-datepicker__month-container {
      width: 100%;
      float: none;
      height: 100%;
    }
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .react-datepicker__header {
      @extend .bg-gradient-success;
      border-radius: 0;
    }
    .react-datepicker__month {
      padding: 1.5rem 0;
    }
    .react-datepicker__current-month {
      background: transparent;
      border-radius: 0;
      color: $white;
      padding: 2rem 1rem;
      font-size: 2rem
    }
    .react-datepicker__navigation {
      font-size: 1.5rem;
      color: $white;
      top: 55px;
      &.react-datepicker__navigation--previous {
        border-right-color: $white;
      }
      &.react-datepicker__navigation--next {
        border-left-color: $white;
      }
    }
    .react-datepicker__day-name {
      padding:1.5rem .25rem;
      font-size: .875rem;
      color: $white; 
      font-weight: bold;
    }
    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            padding: 1.25rem 0;
            line-height: auto;
            margin: 0;
            @media (max-width: 991px) {
              padding: .5rem .5rem;
            }
            &.react-datepicker__day--today {
              &::before {
                background: theme-color(success);
                left: 58%
              }
            }
            &:hover {
              background: transparent;
            }
          }
        }
      }

    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
  }
}

.rounded-legend {
  ul {
    .rtl & {
      padding-right: 0;
    }
    li {
      list-style-type: none;
      color: $text-muted;
      font-size: .75rem;
      .legend-dots {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .5rem;
        .rtl & {
          margin-left: .5rem;
        }
      }
    }
  }
  &.legend-horizontal {
    @include display-flex;
    ul {
      li {
        display: inline-block;
        margin-right: 1.5rem;
        .rtl & {
          margin-right: auto;
          margin-left: 1.5rem;
        }
      }
    }
  }
  &.legend-top-right {
    ul {
      float: right;
      .rtl & {
        float: left;
      }
    }
  }
  &.legend-vertical {
    ul {
      li {
        margin-top: 1rem;
      }
    }
  }
}
